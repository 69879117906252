import styled, { css } from 'styled-components';
import toggleIcon from '../../assets/icon-toggle.png';
export const Container = styled.div`
    ${({ theme }) => css`
        height: 50px;
        display: flex;
        position: fixed;
        top: 30px;
        left: 0;
        z-index: 900;
        @media (min-width: 576px) {
            top: unset;
            bottom: 30px;
        }
    `}
`;
export const BackgroundDetail = styled.div`
    ${({ theme, bgColor }) => css`
        background-color: ${bgColor};
        display: flex;
    `}
`;

export const Navbar = styled.nav`
    ${({ theme }) => css`
        height: 100%;
        background-color: transparent;
        display: flex;
        box-shadow: -15px 1px 5px rgba(0, 0, 0, 0.3) ;
        position: relative;
    `}
`;
export const Controls = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
        display: flex;
        gap: 30px;
        padding: 0 10px;
    `}
`;
export const Control = styled.button.attrs({ type: 'button'})`
    ${({ theme }) => css`
        background-color: transparent;
        border: 1px solid transparent;
    `}
`;
export const LogoContainer = styled.div`
    ${({ theme }) => css`
    background-color: ${theme.colors.white};
    display: flex;
    align-items: center;
    padding: 0 0 0 15px;
    @media (min-width: 576px) {
        padding: 0 42px;    
    }
    `}
`
export const Logo = styled.img`
    ${({ theme }) => css`
        width: 80%;
        @media (min-width: 576px) {
            width: unset;
        }
    `}
`;

export const LogoAnchor = styled.a.attrs({ target: '_blank'})`
    ${({ theme }) => css`
    text-decoration: none;
    @media (min-width: 576px) {
    
    }
    `}
`

export const ChatButton = styled(Control)`
    ${({ theme }) => css`
    `}
`;
export const Icon = styled.img`
    ${({ theme }) => css`

    `}
`;
export const CollapseButton = styled.button.attrs({ type: 'button' })`
    ${({ theme }) => css`
        background-color: ${theme.colors.mainColor};
        color: ${theme.colors.white};
        text-transform: uppercase;
        padding: 0 10px;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        @media (min-width: 1280px) {
            display: none;
        }
    `}
`;

export const ToggleIcon = styled.img.attrs({ src: toggleIcon })`
    ${({ theme }) => css`
        margin-right: 12px;
        /* @media (min-width: 1024px) { */
        @media (min-width: 1280px) {
            display: none;  
        }
    `}
`

export const NavbarItensWrapper = styled.ul`
    ${({ theme, active }) => css`
        padding: 20px 25px;
        margin: 0;
        height: unset;
        list-style: none;
        background-color: ${theme.colors.mainColor};
        align-items: center;
        justify-content: center;
        gap: 30px;
        display: ${active ? 'flex' : 'none'};
        position: absolute;
        top: 100%;
        flex-direction: column;
        transition: all 0.300ms ease;
        width: 97%;
        /* overflow: auto; */
        @media (min-width: 576px) {
            bottom: 100%;
            top: unset;
        }
        /* @media (min-width: 1024px) { */
        @media (min-width: 1280px) {
            width: unset;
            position: unset;
            padding: 0 25px;
            margin: 0;
            height: 100%;
            list-style: none;
            background-color: ${theme.colors.mainColor};
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 30px;
        }
    `}
`;
export const NavbarItem = styled.li`
    ${({ theme }) => css`
        /* min-width: 150px;     */
        min-width: 85px;    
        position: relative;
        @media (min-width: 1024px) {
            min-width: 55px;    
        }
    `}
`;
export const NavbarItemButton = styled(Control)`
    ${({ theme, actived }) => css`
        text-transform: uppercase;
        color: ${actived ? theme.colors.secondaryColor : theme.colors.white};
        font-weight: ${actived ? 700 : 400 };
        word-wrap: break-word;
        margin: auto;
        font-size: 16px;
        min-height: 40px;
        letter-spacing: 2.5px;
        @media (min-width: 1024px) {
            font-size: 12px;
        }
        @media (min-width: 1280px) {
            font-size: 10px;
        }
    `}
`;
export const Triangle = styled.div`
    ${({ theme, borderColor }) => css`
        border-left: 10px solid ${theme.colors.white};
        border-right: 0px solid ${theme.colors.white};
        border-bottom: 50px solid ${borderColor};
    `}
`;
export const InvertTriangle = styled.div`
    ${({ theme, borderColor, bgColor }) => css`
        border-left: 10px solid ${borderColor};
        border-right: 0px solid transparent;
        border-bottom: 50px solid transparent;
        background-color: ${bgColor};
    `}
`;