import { useEffect, useContext } from 'react';
import { PDFModalContext } from '../context/PDFModalContext';
import { DiagonalTabContext } from '../context/DiagonalTabContext';
import { VideoCarrosselModalContext } from '../context/VideoCarrosselModalContext';
import { VideoModalContext } from '../context/VideoModalContext';
import { DataContext } from '../context/DataContext';
import { LinkExternoModalContext } from '../context/LinkExternoModalContext';
import { BannerModalContext } from '../context/BannerModalContext';
import { ReloadFirebase, GetInitialLiveLink, ObserveLiveLink, registerCount } from './firebaseFn';

const WindowFunctions = ({ children }) => {    
    const { setOpen, setPDFUrl }  = useContext(PDFModalContext);
    const { clearAndAddData, clearData } = useContext(DiagonalTabContext);
    const { setOpen:setOpenCarrosselModal } = useContext(VideoCarrosselModalContext);
    const { setOpen:setOpenVideoModal, setVideo } = useContext(VideoModalContext);
    const { setOpen:setOpenLinkExterno, setData:setDataLinkExterno } = useContext(LinkExternoModalContext);
    const { setOpen:setOpenBanner } = useContext(BannerModalContext);
    const { machine } = useContext(DataContext);
    
    useEffect(() => {  
        window.openPDF = (pdfUrl) => {
            setPDFUrl(pdfUrl);
            setOpen(true);
        }

        window.openCarrosselModal = () => {
            setOpenCarrosselModal(true)
        }

        window.openVideo = (url, horizontal = true) => {
            setOpenVideoModal(true);
            setVideo({ link: url, horizontal });
        }

        window.setVideoUrl = (url) => {
            setVideo(prev => ({...prev, link: url }));
        }

        window.openBanco = () => {
            setOpenLinkExterno(true);
            setDataLinkExterno({
              text: 'Banco',
              href: 'https://www.deere.com.br/pt/financiamento/banco-john-deere/'
            }) 
        }
        window.openConsorcio = () => {
            setOpenLinkExterno(true);
                setDataLinkExterno({
                  text: 'Banco',
                  href: 'https://www.consorciojohndeere.com.br/'
                }) 
        }

        window.openFidelidade = () => {
            setOpenBanner(true)   
        }

        // ReloadFirebase();
        GetInitialLiveLink();
        // ObserveLiveLink();

        window.windowRegisterCounter = (location) => {
            // registerCount(location);
        }

    },[]);

    useEffect(() => {  

        window.addToDiagonalTab = (reference) => {            
            const selected = machine.find((item) => item.reference === reference);  
            clearAndAddData(selected);
        }

        window.clearDiagonalTab = () => {
            clearData();
        }

    },[machine]);

    return <>{children}</>
}
export default WindowFunctions;