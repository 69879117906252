import styled, { css } from 'styled-components';
import { Container as TransparentContainer } from '../../components/TransparentContainer/styles';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        position: relative;
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        @media (max-width: 768px){
            flex-direction: column;
        }
    `}
`;
export const Block = styled.div`
    ${({ theme, flexBasis, center }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        display: flex;
        flex-basis: ${flexBasis};
        ${TransparentContainer}{
            padding: ${theme.spacings.gutter}px;
            flex-grow: 1;
            /* display: flex; */
            display: ${center ? 'flex': 'block' };
            flex-direction: ${center ? 'column': 'unset' };
            justify-content: ${center ? 'center': 'unset' };
        }
    `}
`;

export const DataWrapper = styled.div`
    ${({ theme, bgColor }) => css`
        display: flex;
        gap: 10px;
    `}
`;

export const AreaWrapper = styled.div`
    ${({ theme, bgColor }) => {
        const setColor = (area) => {
            return (area === "") ? theme.colors.secondaryColor : theme.colors.mainColor
        }
        return css`
            display: flex;
            flex-direction:column;
            padding: ${theme.spacings.gutter / 2 }px;
            background-color: ${setColor(bgColor)};
            border-radius: 5px;
            gap: ${theme.spacings.gutter / 2 }px;
        `}
    }
`;
export const InsideWrapper = styled.div`
    ${({ theme, bgColor }) => css`
            display: flex;
            flex-direction:column;
            gap: ${theme.spacings.gutter / 2 }px;
    `}
`

export const AreaWrapperContent = styled.span`
    ${({ theme, bgColor }) =>  css`
            
    `}
`;

export const AreaTitle = styled.h3`
    ${({ theme, bgColor }) =>  css`
        color: ${theme.colors.white};
        margin-bottom: 0;
        font-weight: 600;
        padding: 15px 5px;
    `}
`;

export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: 1px solid transparent;
    `}
`;

export const HeadingContainer = styled.div`
    ${({ theme }) => css`
        margin: 40px;
    `}
`

export const MainHeading = styled.h1`
    ${({ theme }) => css`
        color: ${theme.colors.mainDark};
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        @media (min-width: 576px) {
            font-size: 36px;
        }
    `}
`;

export const SecondaryHeading = styled.h5`
    ${({ theme }) => css`
        color: ${theme.colors.mainDark};
        font-weight: 300;
        font-size: 16px;
        text-align: center;
        margin: 0;
        @media (min-width: 576px) {
            font-size: 21px;
        }
    `}
`;
export const Paragraph = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.mainDark};
        font-weight: 300;
        font-size: 13px;
        text-align: center;
        margin: 0;
    `}
`;
export const CallbackMessagem = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.mainDark};
        font-weight: 300;
        font-size: 16px;
        text-align: center;
        margin: 0;
    `}
`;