import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, onValue, onChildChanged, runTransaction  } from "firebase/database";

// const firebaseConfig = {
//   apiKey: "AIzaSyDmrGtZfUQqeECLaoqem1ey5ItkPU4OaB4",
//   authDomain: "jd-agrishow-2022.firebaseapp.com",
//   databaseURL: "https://jd-agrishow-2022-default-rtdb.firebaseio.com",
//   projectId: "jd-agrishow-2022",
//   storageBucket: "jd-agrishow-2022.appspot.com",
//   messagingSenderId: "1072481742095",
//   appId: "1:1072481742095:web:b52fd98c98feb5a48a627a",
//   measurementId: "G-SMWTJ95RV3"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const database = getDatabase(app);

export function ReloadFirebase(){
    // const reloadRef = ref(database, 'config/reload');
    // return new Promise(function(resolve, reject){
    //         try {
    //             resolve(onChildChanged(reloadRef, function(data){
    //                 window.ResetPlatform();
    //             }))
    //         } catch (error) {
    //             reject(error)
    //         }
    //     }
    // );
    return
}

export function GetInitialLiveLink(){
    // const reloadRef = ref(database, 'config/live');
    // return new Promise(function(resolve, reject){
    //         try {
    //             resolve(onValue(reloadRef, function(data){
    //                 window.liveDay = new Date().getDate();           
    //                 window.liveURL = data.val().url;                    
    //             }))
    //         } catch (error) {
    //             reject(error)
    //         }
    //     }
    // );

    window.liveDay = new Date().getDate();
    window.liveURL = "https://www.youtube.com/embed/CpNinwn37gA";
}

export function ObserveLiveLink(){
    // const reloadRef = ref(database, 'config/live');
    // return new Promise(function(resolve, reject){
    //         try {
    //             resolve(onChildChanged(reloadRef, function(data){
    //                 window.liveURL = data.val().url;
    //                 window.setVideoUrl(data.val().url);
    //             }))
    //         } catch (error) {
    //             reject(error)
    //         }
    //     }
    // );
}

export function registerCount(counter){
    // const counterRef = ref(database, 'register/' + counter);
    // runTransaction(counterRef, function(data){        
    //     if(data){
    //         data += 1;
    //     }else{ 
    //         data = 1;
    //     }
    //     return data;
    // });   
    return
}



// export function StartFirebase(){
//     database.ref('config/reload').on('child_changed', function (data) {  
//         console.log(data.val());
//     });
// }

// export function SearchCard(text, dispatch){

//     window.searchNetworkCards = [];

//     if(text.length > 2){
//         firebase.firestore().collection("cards").where("searchKeywords", "array-contains", text.toLowerCase()).get()
//         .then(function (snapshot) {
//             snapshot.forEach((doc) => {   
//                 let newDoc = {
//                     name:doc.data().name,
//                     company: doc.data().company,
//                     email: doc.data().email,
//                     occupation: doc.data().hole,
//                     phone: doc.data().phone,
//                     socialMedia: {
//                         instagram: doc.data().instagram,
//                         linkedin: doc.data().linkedin
//                     }
//                 }
//                 window.searchNetworkCards.push(newDoc)  
//             });
    
//             dispatch(setNetworkingData(window.searchNetworkCards))
//         })
//         .catch((error) => {
//             console.warn(error);
//         });
//     }else{
//         dispatch(setNetworkingData(window.allNetworkCards))
//     }
    
// }