import axios from 'axios';

import { useEffect, useState, createContext } from 'react';

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
    const [data, setData] = useState({
        lecture:[],
        machine:[],
        speaker:[]
    }); 

    const [contatos, setContatos] = useState({});

    const [loaded, setLoaded] = useState(false);
    const [trys, setTrys] = useState(3);
    const value = {
        ...data,
        contatos,
        loaded,
        trys
    };
    
    const axiosRetry = (url, n) => {
        return axios.get(url).catch((err) => {
            setTrys(prev => --prev );
            if(n === 1) throw err;
            return axiosRetry(url, n - 1 )
        })
    }
    
    useEffect(() => {
        axiosRetry("/data/updateJson.json", trys)
            .then((res) => {
                setData(res.data);
                setLoaded(true)
            }) 
            .catch(console.log) 
    },[]);

    useEffect(() => {
        axios.get('data/contatos.json')
            .then((res) =>  setContatos(res.data) )
                .catch(console.error)
    },[]);


    return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}