import P from 'prop-types';
import { useTheme } from 'styled-components';
import * as Styled from './styles';


import CarrosselPure from '../../components/CarrosselPure';
import ModalVerticalFlexSize from '../../components/ModalVerticalFlexSize';
import NativeVideo from '../../components/NativeVideo';

import { useContext, useEffect, useState, createRef, useRef } from 'react';
import { VideoCarrosselModalContext } from '../../context/VideoCarrosselModalContext';

import TransparentContainer from '../../components/TransparentContainer';

import iconClose from '../../assets/icon-close-2.png';

const VideoCarrosselModal = () => {
    const theme = useTheme();
    const { open, videos, setOpen, video } = useContext(VideoCarrosselModalContext);
    
    const [next, setNext] = useState(0);

    const [nextButton, setNextButton] = useState(true);
    const [stepButton, setStepButton] = useState(false);
    const [reloadButton, setReloadButton] = useState(false);

    useEffect(() => {
        const { title } = document;
        document.title = `${title} - Videos`;
        return () => {
            document.title = title;
        }
    },[]);

    useEffect(() => {
        window.pauseSound();
        return () => window.resumeSound();
    },[])

    const videoRef0 = useRef();
    const videoRef1 = useRef();
    const videoRef2 = useRef();
    const videoRef3 = useRef();
    const videoRef4 = useRef();

    const videosRefs = [videoRef0, videoRef1, videoRef2,videoRef3,videoRef4];

    const [loadeds, setLoaded] = useState({ 
        video0: false,
        video1: false,
        video2: false,
        video3: false,
        video4: false,
    } );

    useEffect(() => {
        if(videoRef0.current.readyState === 0 ) {
            setLoaded(prev => ( {...prev, video0: true } ));
        }
        if(videoRef1.current.readyState === 0 ) {
            setLoaded(prev => ( {...prev, video1: true } ));
        }
        if(videoRef2.current.readyState === 0 ) {
            setLoaded(prev => ( {...prev, video2: true } ));
        }      
        if(videoRef3.current.readyState === 0 ) {
            setLoaded(prev => ( {...prev, video3: true } ));
        }      
        if(videoRef4.current.readyState === 0 ) {
            setLoaded(prev => ( {...prev, video4: true } ));
        }      
    },[videoRef0.current,videoRef1.current,videoRef2.current,videoRef3.current,videoRef4.current])

    useEffect(() => {
       play(videoRef0); 
    },[]);

    useEffect(() => {
        videoRef0.current.addEventListener('play', () =>  {
            videoRef4.current.pause();
            videoRef4.current.currentTime = 0;
            setReloadButton(false)
        } );
        videoRef0.current.addEventListener('ended', () => {
            setNextButton(true);
            videoRef0.current.autoplay = true;
            videoRef0.current.play();
        } );
    },[videoRef0.current]);

    useEffect(() => {
        videoRef1.current.addEventListener('play', () => {
            videoRef0.current.pause();
            videoRef0.current.autoplay = false;
            setNextButton(false);
        }) 
        videoRef1.current.addEventListener('ended', () => {
            setStepButton(true);
            createLoop(videoRef1, 20)
        }) 
    },[videoRef1.current]);

    useEffect(() => {
        videoRef2.current.addEventListener('play', () => {
            videoRef1.current.pause();
            videoRef1.current.currentTime = 0;
            setStepButton(false);
        });
        videoRef2.current.addEventListener('ended', () => {
            setNextAndPlay(3, videoRef3 )
        });
    },[videoRef2.current]);

    useEffect(() => {
        videoRef3.current.addEventListener('ended', () => {
            setNextAndPlay(4, videoRef4 )
        });
    },[videoRef3.current]);

    useEffect(() => {
        videoRef4.current.addEventListener('ended', () => {
            // duration: 46.566
            createLoop(videoRef4, 40);
            setReloadButton(true);
        });
    },[videoRef4.current]);

    const createLoop = (ref, timeStart) => {
        ref.current.currentTime = timeStart;
        play(ref);
    }

    const play = (ref) => {
        ref.current.play();
    }

    const setNextAndPlay = (nextIndex, ref) => {
        setNext(nextIndex);
        ref.current.play();
    }

    const stepClick = () => {
        videoRef2.current.pause();
        setNextAndPlay(2, videoRef2 )
    }

    const nextClick = () => {
        setNextAndPlay(1, videoRef1);
    }

    const reloadClick = () => {
        setNext(0);
        play(videoRef0)
    }

    return ( <Styled.Container>
                <ModalVerticalFlexSize size={'xl'} show={open} onHideFn={() => setOpen(false)} headerText={''} >
                    <Styled.Content>
                    <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'}>
                            <TransparentContainer bgColor={theme.colors.white} alpha={0.9}>
                                <CarrosselPure 
                                    time={10000} 
                                    drillIndex={next}
                                    data={[
                                        <NativeVideo ref={videoRef1} videoUrl={`/videos/${videos[1]}`}/>,
                                        <NativeVideo ref={videoRef2} videoUrl={`/videos/${videos[2]}`}/>,
                                        <NativeVideo ref={videoRef3} videoUrl={`/videos/${videos[3]}`}/>,
                                        <NativeVideo ref={videoRef4} videoUrl={`/videos/${videos[4]}`}/>,
                                        <NativeVideo ref={videoRef0} videoUrl={`/videos/${videos[0]}`}/>,
                                    ]} 
                                    />
                                    {videoRef0.current && <>
                                        {nextButton  && <Styled.NextButton top={videoRef0.current.offsetHeight} onClick={nextClick} />}
                                        {stepButton && <Styled.StepButton top={videoRef0.current.offsetHeight} onClick={stepClick} />}
                                        {reloadButton && <Styled.ReloadButton top={videoRef0.current.offsetHeight} onClick={reloadClick}/> }
                                        </>
                                    }
                            </TransparentContainer>
                        </Styled.Block>
                    </Styled.Content>
                </ModalVerticalFlexSize>
            </Styled.Container>
    );
};
VideoCarrosselModal.propTypes = {};
export default VideoCarrosselModal;